/* ----------------------------------------------------------------------------
js entry point
---------------------------------------------------------------------------- */
import animateOnScroll from './_elements/animate-on-scroll.js';
import LoginError from './_elements/login-error.js';
import RandomPattern from './_elements/random-pattern.js';
import ThemePicker from './_elements/theme-picker.js';
import * as quicklink from 'quicklink';

quicklink.listen();
