/* ----------------------------------------------------------------------------
switch between color themes
---------------------------------------------------------------------------- */
export default class ThemePicker extends HTMLElement {
  static generateOklch() {
    const l = (Math.random() * 90 + 10).toFixed(2); // Lightness 10 - 100%
    const c = (Math.random() * 0.122 + 0.01).toFixed(2); // Chroma 0.01 - 0.132
    const h = Math.floor(Math.random() * 360); // Hue 0 - 360deg
    return `${l}% ${c} ${h}`;
  }

  static lightnessDelta(color1, color2) {
    const lightness1 = parseFloat(color1.split('%')[0]);
    const lightness2 = parseFloat(color2.split('%')[0]);
    return Math.abs(lightness1 - lightness2);
  }

  generateTheme() {
    const c1 = ThemePicker.generateOklch();
    const c2 = ThemePicker.generateOklch();
    const delta = ThemePicker.lightnessDelta(c1, c2);
    if (delta < 40) {
      this.generateTheme();
    } else {
      this.target.style.setProperty('--color-random-text', `oklch(${c1})`);
      this.target.style.setProperty('--color-random-sheet', `oklch(${c2})`);
      localStorage.setItem('color-text', `oklch(${c1})`);
      localStorage.setItem('color-sheet', `oklch(${c2})`);
    }
  }

  save(theme) {
    this.target.setAttribute('theme', theme);
    localStorage.setItem('theme', theme);
    this.update(theme);
  }

  update(theme) {
    if (this.querySelector('.--active')) {
      this.querySelector('.--active').classList.remove('--active');
    }
    this.querySelector(`[data-theme="${theme}"]`).classList.add('--active');
  }

  selectable() {
    const themeButtons = this.querySelectorAll('[data-theme]');
    themeButtons.forEach((element) => {
      element.addEventListener('click', (e) => {
        const newTheme = element.getAttribute('data-theme');
        this.save(newTheme);
        if (newTheme === 'random') {
          this.generateTheme();
        }
      });
    });
  }

  closeable() {
    document.addEventListener('click', (e) => {
      const isClickInside = this.contains(e.target);
      const isOpen = this.querySelector('details[open]');
      if (!isClickInside && isOpen) {
        this.querySelector('details[open]').removeAttribute('open');
      }
    });
  }

  connectedCallback() {
    this.target = document.documentElement;
    this.selectable();
    this.closeable();
    this.update(this.target.getAttribute('theme'));
  }
}

customElements.define('theme-picker', ThemePicker);